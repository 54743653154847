import * as React from "react"
import {Helmet} from "react-helmet";
import Amplify, { Auth, Storage } from 'aws-amplify';
import awsconfig from '../../aws-exports';
import { withAuthenticator } from '@aws-amplify/ui-react'
import ToolCard from '../../components/toolcard';
import { Link } from "gatsby"

Amplify.configure(awsconfig);


// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}


function processStorageList(result) {
  let files = []
  result.forEach(res => {
    console.log(res);
    if ((res.size) && (res.key.endsWith(".ovpn"))) {
      files.push(res)
    }
     
  })
  return (files);
}

class VpnPage extends React.Component {

   state = {
        email: "",
        settings_file: "#",
        vpns: []
    }


  componentDidMount() {
    Auth.currentAuthenticatedUser().then( data => {
        let myIdentity = JSON.parse(data.attributes.identities)[0];
        console.log(data);
        this.setState({
            email: myIdentity.userId,
            //settings_file: presignSettingsFile()
        });

        Storage.list('vpn', { level: 'private' })
          .then(result => {
            this.setState( { ...this.state, vpns: processStorageList(result)});
           })
          .catch(err => console.log(err));



        Storage.get( 'vpn/engineering.ovpn', 
          {
              level: "private",
              download: false,
              expires: 900,
              contentType: "ovpn" // set return content type, eg "text/html"
          }).then ( s3data => {
            console.log(s3data);
            this.setState( {
              ...this.state,
              settings_file: s3data
            });
            
          });

        
        
        
        
      }
      );
  }


  render() {
    

  return (
    <main style={pageStyles}>
      <Helmet>
          
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/css/materialize.min.css" />
        <script src="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/js/materialize.min.js"></script>        
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
      </Helmet>
      <title>Spoonflower Engineering</title>
      <div class="container">

      <ul class="collection">
      <li class="collection-item">
        <div class="card blue-grey darken-1">
          <div class="card-content white-text">
              <span class="card-title">Hello {this.state.email}!</span>
              <p>Congratulations! You've successfully authenticated to Ikebana and the MyFleur SSO system.</p>
              
          </div>
        </div>
      </li>
      <li class="collection-item">
          <ToolCard action = "Download Client" link = "https://www.sparklabs.com/viscosity/download/" name = "Viscosity VPN Client" description = "Download and install the Viscosity VPN Client onto your Mac laptop. You'll receive the standard 30-day trial. Spoonflower has a license for this tool. Once installed, email `platform@spoonflower.com` and request your license key."/>
      </li>
      <li class="collection-item">
      <div className="col s12 m6">
        <div className="card blue-grey darken-1">
        <div className="card-content white-text">
            <span className="card-title">Download Settings File</span>
            <p>You'll need to download this settings file, which contains information about our VPN server, as well as information specific to your user account. Do not share this file with anybody.</p>
        </div>
        <div className="card-action">
            { this.state.vpns.map( (vpnProfile,i) => {
              console.log("VPN", vpnProfile);
              return (
                <div>
                <Link target="_blank" to={ vpnProfile.key }>Download { vpnProfile.key }</Link><br/>
                </div>
              );
            })}
        </div>
        </div>
    </div>
      </li>
      <li class="collection-item">
        <div class="col s12 m6">
        <div class="card blue-grey darken-1">
          <div class="card-content white-text">
              <span class="card-title">Configure Viscosity</span>
              <p>You are now ready to configure Viscosity. <br/>
                1. Open Viscosity.<br/>
                2. Open it's preferences.<br/>
                3. In the lower left corner, click the (+) button, then Import Connection --> From File.<br/>
                4. Select the `spoonflower-engineering.ovpn` file that you downloaded from above.<br/>
                5. Connect! Your username is { this.state.email }. Use your MyFleur SSO password.<br/></p>
          </div>
        </div>
        </div>
      </li>
      <li class="collection-item">
        <div class="col s12 m6">
        <div class="card blue-grey darken-1">
          <div class="card-content white-text">
              <span class="card-title">Test</span>
              <p>Let's see if you can access a resource that exists only on the VPN</p>
          </div>
          <div class="card-action">
              <a target="_blank" href="/project/vpn/go/vpntest">Test My VPN</a>
          </div>
          
        </div>
        </div>
        </li>
      </ul>
       
        
        
        
    </div>
    </main>
  )
  }
    

  
}

export default withAuthenticator(
  VpnPage
)
